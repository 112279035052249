<template>
  <div>
    <v-card link @click="routeDetails(unit.meta.id)">
      <v-card-title class="subheading font-weight-bold">
        {{ unit.title }}<v-spacer></v-spacer
        ><v-chip
          v-if="unit.isExternal"
          label
          class="font-weight-medium blue--text text--darken-4"
          color="blue lighten-4"
          ><v-icon small left>mdi-open-in-new</v-icon>Extern</v-chip
        >
      </v-card-title>
      <v-img
        v-if="unit.photoUrl.size400x400"
        :src="unit.photoUrl.size400x400"
        contain
        height="140px"
        class="mb-2"
      ></v-img>
      <v-card
        v-else
        height="140px"
        flat
        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
        class="mb-2 lighten-4 mx-4 text-center d-flex align-center justify-center"
      >
        <v-card-text>
          <div>
            <v-icon class="mb-2"> mdi-alert-circle</v-icon>
            <p>Keine Grafik verfügbar</p>
          </div>
        </v-card-text>
      </v-card>

      <v-card-subtitle
        class="subtitle-1 font-weight-medium"
        v-if="unit.crewCombinations.length > 0"
        >Besetzungen</v-card-subtitle
      >
      <v-card-text v-if="unit.crewCombinations.length > 0">
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="6"
            md="6"
            v-for="(crewCombination, index) in unit.crewCombinations"
            :key="index"
          >
            <v-card outlined>
              <v-card-title class="subtitle-2"
                >{{ crewCombination.title }}
                <span class="ml-1 grey--text font-weight-regular">
                  ({{ crewCombination.strength }})</span
                ><v-spacer></v-spacer
                ><v-chip small label
                  ><v-icon small left>mdi-dumbbell</v-icon
                  >{{ crewCombination.priority }}</v-chip
                ></v-card-title
              >
              <v-card-text v-if="crewCombination.positions.length > 0">
                <template v-for="position in crewCombination.positions">
                  <v-chip
                    class="mr-2 my-1"
                    label
                    small
                    :key="position.id"
                    v-bind:style="
                      createBorderColorStyle(position.position.color)
                    "
                  >
                    <span v-if="!small">{{ position.position.title }}</span>
                    <span v-else>{{ position.position.shortTitle }}</span>
                    <span v-if="position.isOptional">*</span>
                  </v-chip>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <div style="height: 10px"></div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "unit-detail-card",
  components: {},
  props: {
    unit: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    routeDetails(item) {
      this.$router.push({
        name: "availability-units-tab-units-unit-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    createBorderColorStyle(color) {
      const style = "border-left: 12px solid " + color;
      return style;
    },
  },
};
</script>
