<template>
  <div>
    <NavigationBar titel="Einsatzmittel"></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-data-iterator
                :items="units"
                sort-by="priority"
                hide-default-footer
                disable-pagination
                no-data-text="Keine Einsatzmittel vorhanden."
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="unit in props.items"
                      :key="unit.meta.id"
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <unit-detail-card :unit="unit" small></unit-detail-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import UnitDetailCard from "@/components/availability/units/UnitDetailCard.vue";
import { AVAILABILITY } from "@/store/modules.js";
import { GET_UNITS } from "@/store/action-types.js";

export default {
  name: "my-organization-units",
  components: {
    NavigationBar,
    UnitDetailCard,
  },
  data() {
    return {};
  },
  computed: {
    units() {
      return this.$store.state.availability.units;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${AVAILABILITY}${GET_UNITS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
  },
};
</script>
